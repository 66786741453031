
$family-primary: "Graphik Arabic Medium", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-sans-serif: "Bungee", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-secondary: "Qatar2022Arabic Bold", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$size-1: 6rem;
$size-2: 3.5rem;

$green: #00cfb7;
$violet-red: #8D1B3D;
$light: #fbf7f0;

$primary: $violet-red;

// o steps

$steps-completed-color: $green;
$steps-active-color: $primary;

// Navbar
$navbar-background-color: transparent;
